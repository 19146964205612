import React, {
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  NavLink,
  Route,
  Redirect,
  Switch,
  useLocation,
  useRouteMatch,
  useHistory,
} from 'react-router-dom';
import { components } from 'react-select';

import BasicTopbar from '../../components/BasicTopbar';

import './Room.scss';
import DataTable from '../../components/DataTable';
import DocumentsSection from '../../components/DocumentsSection/DocumentsSection';
import { useDispatch, useSelector } from 'react-redux';
import {
  getRoomResourceOptions,
  getRoomWithUpdatedFeatures,
  getRoomFeaturesAsOptions,
  // getRoomSharingMeta,
} from '../../redux/modules/Data/selectors';
import {
  deleteRoomFeature,
  fetchFeaturesForRoom,
  fetchRoomByAccessCode,
  fetchRoomById,
  createRoomFeatures,
  deleteRoomFeatures,
  updateRoomFeatures,
  updateRoom,
  createRoomDocument,
  addRoomReport,
  fetchRoomCodes,
  fetchRoomHistory,
  fetchRoomParticipants,
  createRoomHistory,
  fetchRoomByUser,
  swapRoomLinks,
  deleteRoomLink,
  fetchInspectAccessCode,
} from '../../redux/modules/Data/operations';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { isEmpty } from 'lodash-es';
import { Button, MyModal } from '../../components/common';
import { InputSelect, InputStyledTextField } from '../../components/inputs';
import { setNotice } from '../../redux/modules/UI/actions';
import { DropdownAction } from '../../components/common/Dropdown/Dropdown';
import {
  getAuthAccount,
  getAuthUser,
  IsInitLoading,
} from '../../redux/modules/User/selectors';
import {
  fetchCompanyDataTables,
  refreshCompanyToken,
} from '../../redux/modules/Company/operations';
import PageLoader from '../../components/PageLoader';
import { getAllDocuments } from '../../redux/modules/Document/operations';
import { getAllDocumentsForSelect } from '../../redux/modules/Document/selectors';
import {
  getBoxToken,
  getBoxTokenFolderId,
  uploadBoxFile,
} from '../../redux/modules/Cabinet/operations';
import { BoxToken } from '../../redux/modules/Cabinet/selectors';
import ContentPreview from 'box-ui-elements/es/elements/content-preview';
import OutsideFormContainer from '../OutsideForm/OutsideFormContainer';
import ContentUploader from 'box-ui-elements/es/elements/content-uploader';
import { getCompanyDataTables } from '../../redux/modules/Company/selectors';
// import { DataRoomLinks } from '../InfoSidebar/InfoSidebar';
// import classNames from 'classnames';
import { RoomSettings } from './RoomSettings';
import Sidebar from '../Sidebar';
import { RoomReports } from './RoomReports';
import { DocumentItem } from '../PortalView/PortalView';
import { generateBem } from '../../utils/generateBem';
// import UseWindowSize from '../../utils/UseWindowSize';
import { AddLinkModal } from '../InfoSidebar/InfoSidebar';
import classNames from 'classnames';
import UseWindowSize from '../../utils/UseWindowSize';
import { onLogout } from '../../redux/modules/User/operations';

const Room = () => {
  const dispatch = useDispatch();
  const { url, path, params } = useRouteMatch();
  const { room_access_code } = params;
  const { pathname, state = {} } = useLocation();
  const { width } = UseWindowSize();
  const { push, replace } = useHistory();

  const currentUser = useSelector(getAuthUser);
  const currentAccount = useSelector(getAuthAccount);
  const isInitLoading = useSelector(IsInitLoading);
  const { isAuthenticated } = currentUser;
  const { accountId } = currentAccount;
  const room = useSelector(state => getRoomWithUpdatedFeatures(state, room_access_code));
  let {
    account_id: passedAccountId,
    message: errorMessage,
    code,
    identifier,
    is_admin_user,
    outside_forms = [],
    user_email,
    room: {
      account_id,
      account_project_id,
      data_access_code,
      documents = [],
      faqs = [],
      features = [],
      infoCardFeatures = [],
      id,
      isChecklistsAvailable,
      label,
      links = [],
      open,
      require_auth,
      report_feature_types = [],
      strict,
      type,
    } = {},
  } = room || {};
  const limitedUserEmail = user_email || identifier;
  if (!is_admin_user && parseInt(currentAccount.accountId) === account_id) {
    is_admin_user = true;
  } else if (is_admin_user && parseInt(currentAccount.accountId) !== account_id) {
    is_admin_user = false;
  }

  const [addFeatureMeta, setAddFeatureMeta] = useState({});
  const [addResourceMeta, setAddResourceMeta] = useState({});
  // const [isAddDocumentOpen, setIsAddDocumentOpen] = useState(false);
  const [isAddReportOpen, setIsAddReportOpen] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [initialized, setInitialized] = useState(!!state?.initialized);
  const [isRoomFetched, setIsRoomFetched] = useState(!!state?.initialized);
  const [isEditingRoomName, setIsEditingRoomName] = useState(false);
  const [roomName, setRoomName] = useState(label);

  const groupedDocuments = useMemo(() => {
    let documentsNeedingSignature = [];
    let otherDocuments = [];
    documents.forEach(doc => {
      const isActionableDoc =
        doc.status !== 'document.completed' && (doc.recipients || []).length > 0;
      const isUserDocument =
        isActionableDoc &&
        doc.recipients.findIndex(
          v =>
            !v.signed &&
            [
              (currentUser?.email || '').toLowerCase(),
              is_admin_user ? '' : (limitedUserEmail || '').toLowerCase(),
            ].includes(v.email.toLowerCase()),
        ) !== -1;

      // show in 1 if:
      if (isUserDocument || (is_admin_user && isActionableDoc)) {
        documentsNeedingSignature.push(doc);
      }
      if (!currentUser?.email && !limitedUserEmail) {
        if ((doc.recipients || []).length === 0) {
          otherDocuments.push(doc);
        }
      } else if (!isUserDocument) {
        otherDocuments.push(doc);
      }
    });
    return {
      documentsNeedingSignature,
      otherDocuments,
    };
  }, [currentUser?.email, documents, is_admin_user, limitedUserEmail]);

  const { documentsNeedingSignature = [], otherDocuments = [] } = groupedDocuments;

  const filteredResources = useMemo(
    () =>
      features.filter(
        ([resourceTypeKey, resourceType]) =>
          resourceTypeKey !== 'Document' &&
          (width < 1240 ||
            (isChecklistsAvailable && resourceType.resource_type_id !== 25) ||
            (!isChecklistsAvailable && resourceType.resource_type_id !== 2)),
      ),
    [features, isChecklistsAvailable, width],
  );

  const connectedFormAccessCode =
    outside_forms[0]?.access_code || outside_forms[0]?.questionnaire?.access_code;

  const isInfoBarShowing =
    isEditing ||
    otherDocuments.length > 0 ||
    links.length > 0 ||
    infoCardFeatures.length > 0;

  useEffect(() => {
    if (!isInitLoading && !initialized) {
      dispatch(fetchInspectAccessCode(room_access_code));
      dispatch(fetchRoomByAccessCode(room_access_code));
    }
  }, [room_access_code, isInitLoading, initialized, dispatch]);

  useEffect(() => {
    const accId = account_id || passedAccountId;
    let historyAction = 'visit_room';
    let historyLabel = 'Visited Room';
    if (window.location.href.includes('outside-form')) {
      historyAction = 'visit_form';
      historyLabel += ' Standing Form';
    }

    if (!initialized && accId && isAuthenticated) {
      setInitialized(true);
      dispatch(refreshCompanyToken(accId, null, false, true)).then(
        p => {
          dispatch(getBoxToken(accId));
          dispatch(fetchRoomCodes(id, accId));
          dispatch(fetchRoomParticipants(id, accId));
          dispatch(
            createRoomHistory(
              room_access_code,
              { action: historyAction, type: 'visit', label: historyLabel },
              id,
              accId,
            ),
          ).then(
            () => {},
            err => dispatch(fetchRoomHistory(room_access_code, id)),
          );
          const isStandingFormInUrl = window.location.href.includes('outside-form');
          const accessCode = room_access_code || data_access_code;
          let pathname = `/data-room/v1/${accessCode}`;
          if (isStandingFormInUrl) {
            pathname += `/outside-form/${accessCode}`;
          } else {
            pathname += '/overview';
          }
          dispatch(fetchRoomById(id, accId, accessCode, outside_forms)).then(
            v => {
              setIsRoomFetched(true);
              if (!window.location.href.includes(pathname)) {
                replace({ pathname, state: { initialized: true } });
              }
            },
            err => setIsRoomFetched(true),
          );
        },
        error => {
          dispatch(fetchRoomByUser(id)).then(
            v => {
              if (
                v.room.data_access_code !== room_access_code &&
                !window.location.href.includes('outside-form')
              ) {
                dispatch(
                  createRoomHistory(
                    v.room.data_access_code,
                    { action: historyAction, type: 'visit', label: historyLabel },
                    id,
                  ),
                );
                // replace(`/data-room/${v.room.data_access_code}/overview`);
              } else {
                dispatch(
                  createRoomHistory(
                    room_access_code,
                    { action: historyAction, type: 'visit', label: historyLabel },
                    id,
                  ),
                );
              }
              setIsRoomFetched(true);
            },
            err => {
              dispatch(
                createRoomHistory(
                  room_access_code,
                  { action: historyAction, type: 'visit', label: historyLabel },
                  id,
                ),
              );
              setIsRoomFetched(true);
            },
          );
        },
      );
    } else if (!initialized && !isAuthenticated && accId) {
      setIsRoomFetched(true);
      setInitialized(true);
      dispatch(
        createRoomHistory(
          room_access_code,
          {
            action: historyAction,
            type: !!errorMessage ? 'visit_attempt' : 'visit',
            label: historyLabel,
          },
          id,
        ),
      );
    }
    if (state?.initialized) {
      window.history.replaceState({}, document.title); // Resets location
    }
  }, [
    id,
    account_id,
    data_access_code,
    dispatch,
    errorMessage,
    initialized,
    isAuthenticated,
    passedAccountId,
    replace,
    room_access_code,
    state?.initialized,
    outside_forms,
  ]);

  useEffect(() => {
    if (!pathname.includes('data-room')) {
      setInitialized(false);
    }
  }, [pathname]);

  const roomNameRef = useCallback(
    node => {
      if (node !== null && isEditingRoomName) {
        node.focus();
      }
    },
    [isEditingRoomName],
  );

  const toggleEditing = () => {
    if (isEditing) {
      setRoomName(label);
      setIsEditingRoomName(false);
    }
    setIsEditing(!isEditing);
  };

  const handleRoomNameBlur = e => {
    if (isEditingRoomName || e.target.value !== label) {
      dispatch(updateRoom({ label: e.target.value }, id, account_id)).then(p =>
        dispatch(
          createRoomHistory(
            room_access_code,
            {
              action: 'update_room_name',
              type: 'update',
              label: `Changed Room Name to ${e.target.value}`,
            },
            id,
          ),
        ),
      );
      setIsEditingRoomName(false);
    }
  };

  const handleSignIn = ev => {
    const path = {
      pathname: '/login',
      state: { redirectPathname: pathname, passedEmail: limitedUserEmail || '' },
    };
    if (isAuthenticated) {
      dispatch(onLogout(path));
    } else {
      push(path);
    }
  };

  if (errorMessage && isRoomFetched) {
    let errorLabelArr = [
      'This room link requires you to ',
      <Button
        key="error-message-1-link"
        buttonType="link"
        onClick={handleSignIn}
        size="sm"
      >
        Sign In
      </Button>,
    ];
    if (!!limitedUserEmail) {
      errorLabelArr.push('as "');
      errorLabelArr.push(<strong key="error-message-4-email">{limitedUserEmail}</strong>);
      errorLabelArr.push('".');

      let roomType = 'Room';
      if (type === 'deal') {
        roomType = 'Deal Room';
      } else if (type === 'report') {
        roomType = 'Reporting Room';
      }
      errorLabelArr[0] = `To view your ${roomType}, please`;
    } else {
      if (type === 'deal') {
        errorLabelArr[0] = 'This Deal Room link requires you to ';
      } else if (type === 'reporting') {
        errorLabelArr[0] = 'This Reporting Room link requires you to ';
      }
      errorLabelArr.push('to view.');
    }
    return (
      <div className="reportingRoom__container">
        <BasicTopbar
          accountId={accountId}
          isAuthenticated={isAuthenticated}
          isDataRoom
          limitedUserEmail={limitedUserEmail}
        />
        <div className="reportingRoom__main">
          <div className="reportingRoom__content">
            <h1 className="reportingRoom__header">{label || 'Room Overview'}</h1>
            <div className="reportingRoom__section reportingRoom__section--error">
              {code === 'UNAUTHORIZED' && (
                <h2 className="reportingRoom__error">
                  {errorLabelArr.map((item, ind) => {
                    let updatedItem = item;
                    if (typeof item === 'string') {
                      updatedItem = (
                        <Fragment key={`error-message-${ind}-${item}`}>{item}</Fragment>
                      );
                    }
                    return updatedItem;
                  })}
                </h2>
              )}
              {code !== 'UNAUTHORIZED' && (
                <h2 className="reportingRoom__error">{errorMessage}</h2>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }

  const isMainSectionEmpty =
    documentsNeedingSignature.length === 0 && filteredResources.length === 0;

  return (
    <div className="room__container">
      <BasicTopbar
        accountId={accountId}
        isAuthenticated={isAuthenticated}
        isDataRoom
        limitedUserEmail={limitedUserEmail}
      />
      {isAuthenticated && <Sidebar isDataRoom />}
      {(isInitLoading || !isRoomFetched) && <PageLoader showLongLoadingText />}
      {!isInitLoading && isRoomFetched && (
        <div
          className={`room__main${
            faqs.length > 0 || isEditing ? ' room__main--show-faq' : ''
          }`}
        >
          <div className="room__content">
            <h1 className="room__header">
              <span className="room__header--text">
                {!isEditingRoomName && label}
                {isEditingRoomName && (
                  <InputStyledTextField
                    name="room_name"
                    ref={roomNameRef}
                    value={roomName}
                    onChange={e => {
                      setRoomName(e.target.value);
                    }}
                    onBlur={handleRoomNameBlur}
                    onKeyDown={e => {
                      if (e.key === 'Escape') {
                        setRoomName(label);
                        setIsEditingRoomName(false);
                      }
                    }}
                  />
                )}
                {isEditing && !isEditingRoomName && (
                  <Button
                    buttonType="icon"
                    onClick={e => {
                      setRoomName(label);
                      setIsEditingRoomName(true);
                    }}
                    tooltip="Edit Room Name"
                  >
                    <FontAwesomeIcon icon={['fal', 'edit']} />
                  </Button>
                )}
              </span>
              {is_admin_user && account_id === accountId && (
                <Button
                  buttonType="link"
                  className="room__action"
                  onClick={toggleEditing}
                >
                  <FontAwesomeIcon
                    icon={isEditing ? ['far', 'low-vision'] : ['fal', 'edit']}
                  />
                  {isEditing ? 'Preview Room' : 'Edit Room'}
                </Button>
              )}
            </h1>
            <div className="room__nav">
              <NavLink
                activeClassName="room__nav-link--selected"
                className="room__nav-link"
                to={`${url}/overview`}
                exact
              >
                Overview
              </NavLink>
              {(isEditing || report_feature_types.length > 0) && (
                <NavLink
                  activeClassName="room__nav-link--selected"
                  className="room__nav-link"
                  to={`${url}/reports`}
                  exact
                >
                  Reports
                </NavLink>
              )}
              {outside_forms.length > 0 && (
                <NavLink
                  activeClassName="room__nav-link--selected"
                  className="room__nav-link"
                  to={`${url}/outside-form/${connectedFormAccessCode}`}
                  exact
                >
                  Onboarding Form
                </NavLink>
              )}
              {is_admin_user && (
                <NavLink
                  activeClassName="room__nav-link--selected"
                  className="room__nav-link"
                  to={`${url}/settings`}
                  exact
                >
                  Settings & Sharing
                </NavLink>
              )}
              {isEditing && pathname.includes('reports') && (
                <Button
                  buttonType="link"
                  size="sm"
                  className="room__action"
                  onClick={() => setIsAddReportOpen(true)}
                >
                  <FontAwesomeIcon icon="plus" /> Add Report Table
                </Button>
              )}
            </div>
            <Switch>
              <Route path={`${path}/settings`}>
                <RoomSettings
                  access_code={room_access_code}
                  account_id={account_id}
                  account_project_id={account_project_id}
                  is_admin_user={is_admin_user}
                  label={label}
                  open={open}
                  require_auth={require_auth}
                  roomId={id}
                  strict={strict}
                />
              </Route>
              <Route path={`${path}/outside-form/:access_code`}>
                {routeProps => (
                  <OutsideFormContainer
                    room_access_code={room_access_code}
                    account_id={account_id}
                    is_admin_user={is_admin_user}
                    open={open}
                    require_auth={require_auth}
                    roomId={id}
                    strict={strict}
                    {...routeProps}
                  />
                )}
              </Route>
              <Route exact path={`${url}/overview`}>
                <div className="room__sectionWrapper">
                  {isInfoBarShowing && width > 1239 && (
                    <div
                      className={classNames('room__section', {
                        'room__section--small': !isMainSectionEmpty,
                      })}
                    >
                      <InfoCard
                        room={room}
                        isEditing={isEditing}
                        documents={otherDocuments}
                        room_access_code={room_access_code}
                        setAddFeatureMeta={setAddFeatureMeta}
                        setAddResourceMeta={setAddResourceMeta}
                        isShowingOnSide
                      />
                    </div>
                  )}
                  {(!isMainSectionEmpty || width < 1240) && (
                    <div
                      className={classNames('room__section room__section--overview', {
                        'room__section--infoBarHiding': !isInfoBarShowing && width > 1239,
                      })}
                    >
                      {documentsNeedingSignature.length > 0 && (
                        <DocumentsSection
                          accessCode={room_access_code}
                          accountId={accountId}
                          hideToken
                          isAdmin={is_admin_user}
                          isDataRoom
                          // isSimpleTable={!is_admin_user}
                          isSimpleTable
                          isEditing={isEditing}
                          isHeightUnset
                          limitedUserEmail={limitedUserEmail}
                          sectionLabel="Documents Requiring Signature"
                          documents={documentsNeedingSignature}
                          roomId={id}
                        />
                      )}
                      {width < 1240 && (
                        <InfoCard
                          room={room}
                          isEditing={isEditing}
                          documents={otherDocuments}
                          room_access_code={room_access_code}
                          setAddFeatureMeta={setAddFeatureMeta}
                          setAddResourceMeta={setAddResourceMeta}
                        />
                      )}
                      {/* {(otherDocuments.length > 0 || isEditing) && (
                      <DocumentsSection
                        accessCode={room_access_code}
                        accountId={accountId}
                        hideToken
                        isAdmin={is_admin_user}
                        isDataRoom
                        isSimpleTable={!is_admin_user}
                        isEditing={isEditing}
                        isHeightUnset
                        limitedUserEmail={limitedUserEmail}
                        sectionLabel="Documents"
                        documents={otherDocuments}
                        roomId={id}
                      />
                    )} */}
                      {/* {filteredResources.length < 1 && (
                        <h2>No Overview info included.</h2>
                      )} */}
                      {filteredResources.map(([resourceTypeKey, resourceType], index) => (
                        <ResourceTypeTable
                          key={`resource-type-${index}`}
                          accessCode={room_access_code}
                          account_id={account_id}
                          index={index}
                          isChecklist={resourceType.resource_type_id === 25}
                          isEditing={isEditing}
                          resourceTypeKey={resourceTypeKey}
                          resourceType={resourceType}
                          roomId={id}
                          setAddFeatureMeta={setAddFeatureMeta}
                          setAddResourceMeta={setAddResourceMeta}
                        />
                      ))}
                      {/* {features.map(
                        ([resourceTypeKey, resourceType], index) =>
                          resourceTypeKey !== 'Document' &&
                          (isChecklistsAvailable ||
                            resourceType.resource_type_id !== 2) && (
                            <ResourceTypeTable
                              key={`resource-type-${index}`}
                              accessCode={room_access_code}
                              account_id={account_id}
                              index={index}
                              isEditing={isEditing}
                              resourceTypeKey={resourceTypeKey}
                              resourceType={resourceType}
                              roomId={id}
                              setAddFeatureMeta={setAddFeatureMeta}
                              setAddResourceMeta={setAddResourceMeta}
                            />
                          ),
                      )} */}
                    </div>
                  )}
                </div>
              </Route>
              <Route exact path={`${url}/reports`}>
                <RoomReports
                  accessCode={room_access_code}
                  isEditing={isEditing}
                  account_id={account_id}
                  is_admin_user={is_admin_user}
                  limitedUserEmail={limitedUserEmail}
                  roomId={id}
                  report_feature_types={report_feature_types}
                />
              </Route>
              <Redirect from={`${url}`} to={`${url}/overview`} />
            </Switch>
          </div>
        </div>
      )}
      {isEditing && (
        <AddFeatureModal
          accessCode={room_access_code}
          account_id={account_id}
          roomId={id}
          features={addFeatureMeta.features}
          isOpen={addFeatureMeta.isOpen}
          rootBody={addFeatureMeta.rootBody}
          // Set to empty object if we're seeing flickering data
          onClose={() => setAddFeatureMeta({ ...addFeatureMeta, isOpen: false })}
        />
      )}
      {isEditing && (
        <AddResourceModal
          accessCode={room_access_code}
          account_id={account_id}
          isOpen={addResourceMeta.isOpen}
          resourceTypeId={addResourceMeta.resource_type_id}
          rootBody={addResourceMeta.rootBody}
          roomId={id}
          // Set to empty object if we're seeing flickering data
          onClose={() => setAddResourceMeta({ ...addResourceMeta, isOpen: false })}
        />
      )}
      <AddReportModal
        accessCode={room_access_code}
        account_id={account_id}
        isOpen={isAddReportOpen}
        onClose={() => setIsAddReportOpen(false)}
        reportFeatureTypes={report_feature_types}
        roomId={id}
      />
    </div>
  );
};

const infoBem = generateBem('roomInfoCard');
const InfoCard = ({
  room,
  isEditing,
  isShowingOnSide,
  documents,
  room_access_code,
  setAddFeatureMeta,
  setAddResourceMeta,
}) => {
  const dispatch = useDispatch();
  let {
    room: {
      account_id,
      data_access_code: accessCode,
      infoCardFeatures,
      id: roomId,
      isChecklistsAvailable,
      links = [],
    } = {},
  } = room || {};

  const [addLinkMeta, setAddLinkMeta] = useState({});
  const [isAddDocumentOpen, setIsAddDocumentOpen] = useState(false);

  const filteredInfoCardResources = infoCardFeatures.filter(
    ([resourceTypeKey, resourceType]) =>
      (isChecklistsAvailable && resourceType.resource_type_id === 25) ||
      (!isChecklistsAvailable && resourceType.resource_type_id === 2),
  );

  return (
    <>
      <div className={infoBem()}>
        {(documents.length > 0 || isEditing) && (
          <div className={infoBem('section')}>
            <div className={infoBem('sectionHeader')}>
              <h4>Documents ({documents.length})</h4>
              <div className={infoBem('divider')} />
              {isEditing && (
                <Button
                  buttonType="link"
                  className="room__action"
                  size="xs"
                  onClick={() => setIsAddDocumentOpen(true)}
                >
                  Add Document <FontAwesomeIcon icon="plus" />
                </Button>
              )}
            </div>
            <div
              className={infoBem([
                'sectionRow',
                'sectionRow--flat',
                'sectionRow--documents',
              ])}
            >
              {documents.map((doc, index) => (
                <DocumentItem
                  key={`group-doc-${doc.id}`}
                  accessCode={room_access_code}
                  doc={doc}
                  documents={documents}
                  index={index}
                  isEditing={isEditing}
                  roomId={roomId}
                />
              ))}
            </div>
          </div>
        )}
        {(links.length > 0 || isEditing) && (
          <div className={infoBem('section')}>
            <div className={infoBem('sectionHeader')}>
              <h4>Links ({links.length})</h4>
              <div className={infoBem('divider')} />
              {isEditing && (
                <Button
                  size="sm"
                  buttonType="link"
                  className="room__action"
                  onClick={() => setAddLinkMeta({ index: links.length, isOpen: true })}
                >
                  <FontAwesomeIcon icon="plus" />
                  Add link
                </Button>
              )}
            </div>
            {links.map(({ id, label, link }, index) => (
              <a
                className={infoBem([
                  'sectionRow',
                  isEditing ? 'sectionRow--editing' : null,
                  'sectionRow--link',
                ])}
                key={`room-link-${id}`}
                href={link.includes('http') ? link : 'https://' + link}
                target="_blank"
                rel="noopener noreferrer"
                onClick={ev => {
                  if (isEditing) {
                    return false;
                  }
                }}
              >
                <div className={infoBem(['sectionCol', 'sectionCol--links'])}>
                  <h3>
                    {label}
                    {isEditing && (
                      <DropdownAction
                        Toggle={({ onClick }) => (
                          <Button buttonType="icon" onClick={onClick} size="xs">
                            <FontAwesomeIcon icon="ellipsis-v" />
                          </Button>
                        )}
                      >
                        <Button
                          buttonType="secondary"
                          onClick={e =>
                            setAddLinkMeta({
                              id,
                              index,
                              isOpen: true,
                              label,
                              url: link,
                            })
                          }
                        >
                          Edit Link
                        </Button>
                        <Button
                          buttonType="secondary"
                          isDisabled={index === 0}
                          onClick={e =>
                            dispatch(
                              swapRoomLinks(
                                id,
                                links[index - 1].id,
                                roomId,
                                account_id,
                                accessCode,
                              ),
                            )
                          }
                        >
                          Move up
                        </Button>
                        <Button
                          buttonType="secondary"
                          isDisabled={index + 1 === links.length}
                          onClick={e =>
                            dispatch(
                              swapRoomLinks(
                                id,
                                links[index + 1].id,
                                roomId,
                                account_id,
                                accessCode,
                              ),
                            )
                          }
                        >
                          Move down
                        </Button>
                        <Button
                          buttonType="secondary"
                          isWarning
                          onClick={() =>
                            dispatch(deleteRoomLink(id, roomId, account_id)).then(p =>
                              dispatch(
                                createRoomHistory(
                                  accessCode,
                                  {
                                    action: 'remove_room_link',
                                    type: 'remove',
                                    label: `Removed ${label} Link from Room`,
                                  },
                                  roomId,
                                ),
                              ),
                            )
                          }
                        >
                          Remove Link
                        </Button>
                      </DropdownAction>
                    )}
                  </h3>
                  <h4 className={infoBem('sectionColH4')}>
                    <FontAwesomeIcon
                      className={infoBem('linkIcon')}
                      icon={['fal', 'link-simple']}
                    />
                    {link}
                  </h4>
                </div>
              </a>
            ))}
          </div>
        )}
        {isShowingOnSide &&
          filteredInfoCardResources.map(([resourceTypeKey, resourceType], index) => (
            <ResourceTypeTable
              key={`resource-type-${index}-${resourceTypeKey}`}
              accessCode={room_access_code}
              account_id={account_id}
              index={index}
              isChecklist
              isEditing={isEditing}
              resourceTypeKey={resourceTypeKey}
              resourceType={resourceType}
              roomId={roomId}
              setAddFeatureMeta={setAddFeatureMeta}
              setAddResourceMeta={setAddResourceMeta}
            />
          ))}
      </div>
      {isEditing && (
        <AddLinkModal
          accessCode={accessCode}
          account_id={account_id}
          linkMeta={addLinkMeta}
          onClose={() => setAddLinkMeta({})}
          roomId={roomId}
        />
      )}
      {isEditing && (
        <AddDocumentModal
          accessCode={accessCode}
          account_id={account_id}
          isOpen={isAddDocumentOpen}
          onClose={() => setIsAddDocumentOpen(false)}
          roomId={roomId}
        />
      )}
      {/* <RoomStandingFormsModal
        account_id={companyId}
        isOpen={isStandingFormsModalOpen}
        isRoomOpen={!!open}
        onClose={() => setIsStandingFormsModalOpen(false)}
        standingForms={standingForms}
      />
      <WorkbenchInfoModal
        faqs={faqs}
        isOverview
        isWorkflowCounselShowing={isWorkflowCounselShowing}
        isPortalView
        isOpen={isInfoModalOpen}
        onClose={() => setIsInfoModalOpen(false)}
        sharedForms={sharedForms}
        params={params}
        projectCounsel={projectCounsel}
        reviewInfo={reviewInfo}
      /> */}
    </>
  );
};

const ResourceTypeTable = ({
  accessCode,
  account_id,
  index,
  isChecklist,
  isEditing,
  resourceTypeKey,
  resourceType,
  setAddFeatureMeta,
  setAddResourceMeta,
  roomId,
}) => {
  const dispatch = useDispatch();
  const [resourceTypeInput, setResourceTypeInput] = useState(resourceTypeKey);
  const [isEditingResourceType, setIsEditingResourceType] = useState(false);
  const isCompany = resourceType.resource_type_id === 2;
  // const [isOpen, setIsOpen] = useState(false);

  const handleEditResourceType = () => {
    if (!resourceTypeInput) {
      dispatch(setNotice({ type: 'error', message: 'Cannot update to empty string.' }));
      setIsEditingResourceType(false);
      setResourceTypeInput(resourceTypeKey);
    } else if (resourceTypeInput === resourceTypeKey) {
      setIsEditingResourceType(false);
    } else {
      dispatch(
        updateRoomFeatures(
          resourceType.resources,
          {
            resource_custom_label: resourceTypeInput,
          },
          roomId,
          account_id,
        ),
      ).then(
        e => {
          dispatch(setNotice('Name Updated'));
          dispatch(
            createRoomHistory(
              accessCode,
              {
                action: 'update_table_name',
                type: 'update',
                label: `Updated Table Name to ${resourceTypeInput}`,
              },
              roomId,
            ),
          );
          dispatch(fetchRoomById(roomId, account_id, accessCode));
          setIsEditingResourceType(false);
        },
        error => setIsEditingResourceType(false),
      );
    }
  };
  return (
    <div className={infoBem(['', '--resource'])}>
      <div className={infoBem('section')}>
        <div className={infoBem('sectionHeader')}>
          <h4>
            {!isEditingResourceType && `${resourceTypeKey} Info`}
            {isEditingResourceType && (
              <InputStyledTextField
                value={resourceTypeInput}
                onChange={e => setResourceTypeInput(e.target.value)}
                name="room-resource-type-input"
                onBlur={handleEditResourceType}
              />
            )}
          </h4>
          {isEditing && (
            <>
              <Button
                buttonType="icon"
                size="sm"
                onClick={() => setIsEditingResourceType(true)}
                tooltip="Edit Label"
              >
                <FontAwesomeIcon icon={['fal', 'edit']} />
              </Button>
              {!isCompany && (
                <Button
                  buttonType="icon"
                  size="sm"
                  isDisabled={resourceTypeKey === 'Company'}
                  onClick={() =>
                    setAddResourceMeta({
                      isOpen: true,
                      resource_type_id: resourceType.resource_type_id,
                      rootBody: {
                        resource_variable_name: resourceType.resource_variable_name,
                        resource_custom_label: resourceTypeKey,
                      },
                    })
                  }
                  tooltip="Add Info"
                >
                  <FontAwesomeIcon icon={['fal', 'user-plus']} />
                </Button>
              )}
              <Button
                buttonType="icon"
                size="sm"
                isWarning
                tooltip="Remove Section"
                onClick={() => {
                  dispatch(
                    deleteRoomFeatures(resourceType.resources, roomId, account_id),
                  ).then(e => {
                    dispatch(setNotice('Table Removed'));
                    dispatch(
                      createRoomHistory(
                        accessCode,
                        {
                          action: 'remove_table',
                          type: 'remove',
                          label: `Removed ${resourceTypeKey} Table From Overview`,
                        },
                        roomId,
                      ),
                    );
                  });
                  dispatch(fetchRoomById(roomId, account_id, accessCode));
                }}
              >
                <FontAwesomeIcon icon={['fal', 'trash-alt']} />
              </Button>
            </>
          )}
          <div className={infoBem('divider')} />
        </div>
        {Object.entries(resourceType.resources).map(
          ([resourceKey, resource], resourceIndex) => (
            <ResourceCard
              key={`resource-${resourceKey}-${resourceIndex}`}
              accessCode={accessCode}
              account_id={account_id}
              setAddFeatureMeta={setAddFeatureMeta}
              index={index}
              isChecklist={isChecklist}
              isEditing={isEditing}
              resourceMeta={resource || []}
              resourceIndex={resourceIndex}
              resourceLength={Object.keys(resourceType.resources).length}
              roomId={roomId}
            />
          ),
        )}
      </div>
    </div>
  );
};

const ResourceCard = ({
  accessCode,
  account_id,
  setAddFeatureMeta,
  isChecklist,
  index,
  isEditing,
  resourceMeta,
  resourceIndex,
  resourceLength,
  roomId,
}) => {
  const {
    features,
    resource,
    resource_custom_label,
    resource_id,
    resource_variable_name,
  } = resourceMeta;
  const dispatch = useDispatch();
  const filteredFeatures = features.filter(e => e.feature_type.name !== 'name');

  return (
    <div
      className={infoBem([
        'sectionRow',
        'sectionRow--flat',
        'sectionRow--resource',
        resourceIndex === resourceLength - 1 ? 'sectionRow--last' : null,
      ])}
    >
      <h2 className={infoBem('sectionRowHeader')}>
        {resource}
        {isEditing && (
          <DropdownAction
            Toggle={toggleProps => (
              <Button buttonType="icon" size="sm" {...toggleProps}>
                <FontAwesomeIcon icon="ellipsis-v" />
              </Button>
            )}
          >
            <Button
              buttonType="secondary"
              onClick={() =>
                setAddFeatureMeta({
                  features,
                  isOpen: true,
                  rootBody: {
                    resource_id: resource_id,
                    resource_variable_name: resource_variable_name,
                    resource_custom_label: resource_custom_label,
                    resource,
                  },
                })
              }
            >
              Add Fields
            </Button>
            <Button
              buttonType="secondary"
              isWarning
              onClick={() =>
                dispatch(deleteRoomFeatures(features, roomId, account_id)).then(e => {
                  dispatch(setNotice('Resource Removed'));
                  dispatch(
                    createRoomHistory(
                      accessCode,
                      {
                        type: 'remove',
                        action: 'remove_record',
                        label: `Removed ${resource} from Overview`,
                      },
                      roomId,
                    ),
                  );
                  dispatch(fetchRoomById(roomId, account_id));
                })
              }
            >
              Remove Resource
            </Button>
          </DropdownAction>
        )}
      </h2>
      {/* <div className={infoBem('sectionRowItems')}> */}
      {filteredFeatures.map((feature, featureIndex) => {
        let value = feature.value || '--';
        if (!Array.isArray(value)) {
          let newVal = [];
          let splitValue = value.split('\n');
          splitValue.forEach(e => {
            if (e.trim()) {
              newVal.push(e);
            }
          });
          value = newVal.join('\n') || '--';
        }
        let isCheckmark =
          isChecklist &&
          feature.feature_type.feature_type === 'radio' &&
          (feature.value.includes('Yes') || feature.value.includes('No'));
        return (
          <div
            key={`resource-${resource}-${feature.feature_type.question_label}-${featureIndex}`}
            className={infoBem('sectionCol')}
          >
            {isCheckmark && (
              <div className={infoBem(['sectionColCheck'])}>
                <FontAwesomeIcon
                  className={infoBem('checkmark')}
                  icon={['fal', feature.value.includes('Yes') ? 'check' : 'times']}
                />
                <h4>{feature.feature_type.question_label}</h4>
              </div>
            )}
            {!isCheckmark && (
              <h4 className={infoBem('sectionColH4')}>
                {feature.feature_type.question_label}
                {isEditing && (
                  <Button
                    buttonType="icon"
                    className={infoBem('sectionColDelete')}
                    isWarning
                    onClick={() =>
                      dispatch(
                        deleteRoomFeature(
                          feature.room_feature_id,
                          roomId,
                          account_id,
                          accessCode,
                        ),
                      ).then(p =>
                        dispatch(
                          createRoomHistory(
                            accessCode,
                            {
                              action: 'remove_field',
                              type: 'remove',
                              label: `Removed ${feature.feature_type.question_label} from ${resource} in Overview`,
                            },
                            roomId,
                          ),
                        ),
                      )
                    }
                    tooltip="Remove Field"
                    size="xs"
                  >
                    <FontAwesomeIcon icon={['fal', 'trash-alt']} />
                  </Button>
                )}
              </h4>
            )}
            {!isCheckmark && (
              <h3
                className={infoBem([
                  'sectionColItem',
                  feature.feature === 'email' ? 'sectionColItem--email' : null,
                ])}
              >
                {Array.isArray(value) &&
                  value.map((link, linkIndex) => (
                    <p key={`review-link-${index}-${linkIndex}`}>
                      Link #{linkIndex + 1}:{' '}
                      <a href={link.url} target="_blank" rel="noopener noreferrer">
                        {link.label}
                      </a>
                    </p>
                  ))}
                {!Array.isArray(value) && value}
              </h3>
            )}
          </div>
        );
      })}
    </div>
    // </div>
  );
};

const AddResourceModal = ({
  accessCode,
  account_id,
  isOpen,
  onClose,
  resourceTypeId,
  rootBody = {},
  roomId,
}) => {
  const dispatch = useDispatch();

  const { resource_custom_label } = rootBody;

  const [selectedResource, setSelectedResource] = useState({});
  const [selectedFeatures, setSelectedFeatures] = useState([]);
  const [isInit, setIsInit] = useState(true);
  const { value: resourceId, label } = selectedResource || {};
  const updatedResourceValue = isEmpty(selectedResource)
    ? { label: '', value: '' }
    : selectedResource;

  // const roomFeatures = useSelector(state => getRoomFeatures(state, resourceTypeId));
  const resourceList = useSelector(state =>
    getRoomResourceOptions(state, resourceTypeId),
  );
  const resourceFeatures = useSelector(state =>
    getRoomFeaturesAsOptions(state, resourceId),
  );

  useEffect(() => {
    if (isOpen && isInit) {
      dispatch(
        fetchFeaturesForRoom(1, `?resource_type_id=${resourceTypeId}`, account_id),
      );
      setIsInit(false);
    }
  }, [dispatch, account_id, isOpen, isInit, resourceTypeId]);

  useEffect(() => {
    if (resourceId) {
      dispatch(
        fetchFeaturesForRoom(resourceId, `?resource_id=${resourceId}`, account_id),
      );
    }
  }, [dispatch, account_id, resourceId]);

  const handleClose = () => {
    onClose();
  };

  const handleAddFeatures = () => {
    onClose();
    dispatch(
      createRoomFeatures(
        selectedFeatures,
        { ...rootBody, resource_id: selectedResource.value },
        roomId,
        account_id,
      ),
    ).then(e => {
      dispatch(setNotice('Resource Added'));
      const joinedSelectedFeatures = selectedFeatures
        .map(feature => feature.label)
        .join(', ');
      const sublabel = 'Fields added: ' + joinedSelectedFeatures;
      dispatch(
        createRoomHistory(
          accessCode,
          {
            action: 'add_record',
            type: 'add',
            label: `Added ${label} to Overview`,
            sublabel,
          },
          roomId,
        ),
      );
      dispatch(fetchRoomById(roomId, account_id, accessCode));
    });
  };

  return (
    <MyModal
      isOpen={isOpen}
      onRequestClose={handleClose}
      className="room__modal room__modal--lg"
      overlayClassName="room__modal-overlay"
    >
      <FontAwesomeIcon
        className="room__modal-exit"
        onClick={handleClose}
        icon={['fal', 'times']}
      />
      <h2>Add Resource to {resource_custom_label}</h2>
      <InputSelect
        options={resourceList}
        onChange={e => setSelectedResource(e)}
        label="Select a resource"
        name="select-resource-list"
        value={updatedResourceValue}
      />
      <InputSelect
        options={resourceFeatures}
        isDisabled={isEmpty(selectedResource)}
        isFeatures
        isMulti
        label="Select the fields you want to show for this resource"
        name="select-features-list"
        onChange={e => setSelectedFeatures(e)}
        value={selectedFeatures}
      />
      <div className="room__modalActions">
        <Button buttonType="secondary" onClick={handleClose}>
          Close
        </Button>
        <Button
          isDisabled={isEmpty(selectedResource) || selectedFeatures.length === 0}
          onClick={handleAddFeatures}
        >
          Add Field{selectedFeatures.length > 1 ? 's' : ''}
        </Button>
      </div>
    </MyModal>
  );
};

const AddFeatureModal = ({
  accessCode,
  account_id,
  roomId,
  features,
  isOpen,
  onClose,
  rootBody = {},
}) => {
  const dispatch = useDispatch();

  const { resource, resource_id } = rootBody;

  const resourceFeatures = useSelector(state =>
    getRoomFeaturesAsOptions(state, resource_id, features),
  );

  const [selectedFeatures, setSelectedFeatures] = useState([]);
  const [isInit, setIsInit] = useState(true);

  useEffect(() => {
    if (isOpen && isInit) {
      dispatch(
        fetchFeaturesForRoom(resource_id, `?resource_id=${resource_id}`, account_id),
      );
      setIsInit(false);
    }
  }, [dispatch, account_id, isOpen, isInit, resource_id]);

  const handleClose = () => {
    setSelectedFeatures([]);
    onClose();
    setIsInit(true);
  };

  const handleAddFeatures = () => {
    handleClose();
    let updatedBody = { ...rootBody };
    delete updatedBody.resource;
    dispatch(createRoomFeatures(selectedFeatures, updatedBody, roomId, account_id)).then(
      e => {
        const joinedSelectedFeatures = selectedFeatures
          .map(feature => feature.label)
          .join(', ');
        const sublabel = 'Fields added: ' + joinedSelectedFeatures;
        dispatch(setNotice('Fields Added'));
        dispatch(
          createRoomHistory(
            accessCode,
            {
              action: 'add_fields',
              type: 'add',
              label: `Added Fields for ${resource} to Overview`,
              sublabel,
            },
            roomId,
          ),
        );
        dispatch(fetchRoomById(roomId, account_id, accessCode));
      },
    );
  };

  return (
    <MyModal
      isOpen={isOpen}
      onRequestClose={handleClose}
      className="room__modal"
      overlayClassName="room__modal-overlay"
    >
      <FontAwesomeIcon
        className="room__modal-exit"
        onClick={handleClose}
        icon={['fal', 'times']}
      />
      <h2>Add Fields to {resource}</h2>
      <InputSelect
        options={resourceFeatures}
        isFeatures
        isMulti
        label={`Select any additional features to show for ${resource}`}
        name="select-features-list"
        onChange={e => setSelectedFeatures(e)}
        value={selectedFeatures}
      />
      <div className="room__modalActions">
        <Button buttonType="secondary" onClick={handleClose}>
          Close
        </Button>
        <Button onClick={handleAddFeatures}>
          Add Field{selectedFeatures.length > 1 ? 's' : ''}
        </Button>
      </div>
    </MyModal>
  );
};

const Option = props => (
  <div
    className={`room__modal-option-wrapper${
      props.isSelected ? ' room__modal-option-wrapper--selected' : ''
    }`}
  >
    <components.Option {...props} />
    <h4 className="room__modal-option">{props.data.stateText}</h4>
  </div>
);

const AddDocumentModal = ({ accessCode, account_id, isOpen, onClose, roomId }) => {
  const dispatch = useDispatch();

  const documents = useSelector(getAllDocumentsForSelect);
  const boxToken = useSelector(BoxToken);

  const uploaderRef = useRef(null);

  const [initialized, setInitialized] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [selectedDoc, setSelectedDoc] = useState('');
  const [folderId, setFolderId] = useState('');
  const [error, setError] = useState(false);

  const {
    box_file_id,
    id,
    label = '',
    filename = '',
    stateText = '',
  } = selectedDoc || {};

  useEffect(() => {
    if (isOpen && !initialized) {
      dispatch(getAllDocuments(account_id));
      dispatch(
        getBoxTokenFolderId('/Miscellaneous/Data Room', false, accessCode, account_id),
      )
        .then(payload => setFolderId(payload.upload_folder_id))
        .catch(error => {
          console.error(error);
          setFolderId('0');
        });
      setInitialized(true);
    }
  }, [accessCode, dispatch, account_id, isOpen, initialized]);

  const handleClose = () => {
    setSelectedDoc('');
    onClose();
  };

  return (
    <MyModal
      isOpen={isOpen}
      onRequestClose={handleClose}
      className="room__modal room__modal--lg"
      overlayClassName="room__modal-overlay"
    >
      <FontAwesomeIcon
        className="room__modal-exit"
        onClick={handleClose}
        icon={['fal', 'times']}
      />
      <h2>Add Document from File Cabinet</h2>
      <InputSelect
        components={{ Option }}
        options={documents}
        isFeatures
        label={`Select from documents in your File Cabinet`}
        name="select-features-list"
        onChange={e => {
          setSelectedDoc(e);
          setIsUploading(false);
          setError(false);
        }}
        value={selectedDoc}
      />
      {isUploading && folderId && (
        <ContentUploader
          fileLimit={1}
          rootFolderId={folderId}
          onBeforeUpload={e => {
            setTimeout(() =>
              uploaderRef.current.lastElementChild.lastElementChild.lastElementChild.click(),
            );
          }}
          onUpload={e =>
            dispatch(
              uploadBoxFile(
                e.id,
                { path: '/Miscellaneous/Data Room' },
                false,
                account_id,
              ),
            ).then(payload => {
              setSelectedDoc({
                ...payload.document_history,
                label: payload.document_history.filename,
                value: payload.document_history.box_file_id,
                stateText: 'Uploaded',
              });
              dispatch(getAllDocuments(account_id));
              dispatch(setNotice('File uploaded.'));
              setIsUploading(false);
            })
          }
          token={boxToken}
          measureRef={uploaderRef}
        />
      )}
      <Button buttonType="link" onClick={() => setIsUploading(!isUploading)} size="sm">
        {isUploading ? 'Cancel file upload' : 'Upload File'}
      </Button>
      {!isEmpty(selectedDoc) && !isUploading && (
        <>
          <h4>Selected Document</h4>
          <p>{label || filename}</p>
          <h4>Status</h4>
          <p>{stateText || 'Uploaded'}</p>
          {error && (
            <p className="room__modal-error">
              <FontAwesomeIcon icon="exclamation-circle" />
              {error.message}
            </p>
          )}
          {box_file_id && !error && (
            <div className="room__modal-preview">
              <ContentPreview
                fileId={box_file_id}
                token={boxToken}
                responseInterceptor={e => {
                  if (e.type === 'error' || e instanceof Error) {
                    setError(e.message || 'Unable to preview file at this time.');
                  }
                  return e;
                }}
              />
            </div>
          )}
        </>
      )}
      <div className="room__modalActions">
        <Button buttonType="secondary" onClick={handleClose}>
          Close
        </Button>
        <Button
          isDisabled={isEmpty(selectedDoc)}
          onClick={() => {
            if (!isEmpty(selectedDoc)) {
              dispatch(
                createRoomDocument(
                  { document_history_id: id },
                  roomId,
                  account_id,
                  accessCode,
                ),
              ).then(p => {
                dispatch(
                  createRoomHistory(
                    accessCode,
                    {
                      action: 'add_document',
                      type: 'add',
                      label: `Added ${label || filename} to Room`,
                      sublabel: !!label ? filename : '',
                    },
                    roomId,
                  ),
                );
                handleClose();
              });
            }
          }}
        >
          Add Document
        </Button>
      </div>
    </MyModal>
  );
};

const AddReportModal = ({
  accessCode,
  account_id,
  isOpen,
  onClose,
  reportFeatureTypes,
  roomId,
}) => {
  const dispatch = useDispatch();

  const companyDataTables = useSelector(getCompanyDataTables);

  const filteredTables = companyDataTables.filter(
    table => reportFeatureTypes.findIndex(report => report.id === table.id) === -1,
  );

  const [initialized, setInitialized] = useState(false);
  const [selectedReport, setSelectedReport] = useState(null);
  const [error, setError] = useState(false);

  useEffect(() => {
    if (isOpen && !initialized) {
      dispatch(fetchCompanyDataTables(account_id));
      setInitialized(true);
    }
  }, [dispatch, account_id, isOpen, initialized]);

  const handleClose = () => {
    setSelectedReport(null);
    onClose();
  };

  return (
    <MyModal
      isOpen={isOpen}
      onRequestClose={handleClose}
      className="room__modal room__modal--lg"
      overlayClassName="room__modal-overlay"
    >
      <FontAwesomeIcon
        className="room__modal-exit"
        onClick={handleClose}
        icon={['fal', 'times']}
      />
      <h2>Add Report to Data Room</h2>
      <InputSelect
        options={filteredTables}
        isFeatures
        label={`Select a report table`}
        name="select-report-table"
        onChange={e => {
          setSelectedReport(e);
          setError(false);
        }}
        value={selectedReport}
      />
      {!isEmpty(selectedReport) && (
        <>
          <h4>Selected Report Preview</h4>
          <div className="room__reports-preview">
            <DataTable {...selectedReport} accessCode={accessCode} />
          </div>
          {error && (
            <p>
              <FontAwesomeIcon icon="exclamation-circle" />
              {error.message}
            </p>
          )}
        </>
      )}
      <div className="room__modalActions">
        <Button buttonType="secondary" onClick={handleClose}>
          Close
        </Button>
        <Button
          isDisabled={isEmpty(selectedReport)}
          onClick={() => {
            if (!isEmpty(selectedReport)) {
              dispatch(
                addRoomReport(
                  { feature_type_id: selectedReport.id, user_only: 1 },
                  roomId,
                  account_id,
                  accessCode,
                ),
              ).then(p => {
                dispatch(
                  createRoomHistory(
                    accessCode,
                    {
                      action: 'add_report',
                      type: 'add',
                      label: `Added ${selectedReport.question_label} Report to Reports View.`,
                    },
                    roomId,
                  ),
                );
                handleClose();
              });
            }
          }}
        >
          Add Report
        </Button>
      </div>
    </MyModal>
  );
};

export default Room;
